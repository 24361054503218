<template>
    <div class="app-container" v-loading="loading">
        <el-header class="new-header">
            <div class="new-header-left">
                商家详情
                <el-button type="text" @click="goBack">返回</el-button>
            </div>
        </el-header>
        <div class="content-mian">
            <div class="block-title">
              <i class="el-icon-setting"></i>商家基本信息
            </div>
            <div style="margin-top: 10px;">
              <el-table :data="merchantInfoList" border style="width: 100%" ref="list" stripe>
                <el-table-column prop="merchantName" label="商家名称" align="center"></el-table-column>
                <el-table-column prop="stateName" label="商家状态" align="center"></el-table-column>
                <el-table-column prop="chargingTypeName" label="计费模式" align="center"></el-table-column>
                <el-table-column prop="seatNumber" label="坐席数量上限" align="center"></el-table-column>
                <el-table-column prop="customerRecoveryTypeName" label="客户公海自动回收开关" align="center">
                  <template slot-scope="scope">
                    <span style="margin-right: 10px">{{scope.row.customerRecoveryTypeName}}</span>
                    <el-button type="text" @click="handMerchantRecovery(scope.row)">{{scope.row.customerRecoveryType == 1 ? "关闭" : "开启"}}</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="block-title">
                <i class="el-icon-s-custom"></i>关联用户
                <el-button type="text" @click="handleInitAddUser">添加</el-button>
            </div>
            <div class="block">
                <el-form
                        :inline="true"
                        :model="searchForm"
                        ref="searchForm"
                        @keyup.enter.native="onSubmit"
                >
                    <el-form-item label="用户手机号">
                        <el-input
                                v-model="searchForm.phone"
                                placeholder="用户手机号"
                                size="small"
                                clearable
                                @clear="searchForm.phone = null"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="用户姓名:" prop="userName">
                        <el-input
                                v-model="searchForm.userName"
                                placeholder="用户姓名"
                                size="small"
                                clearable
                                @clear="searchForm.userName = null"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                type="primary"
                                @click="onSubmit"
                                icon="search"
                                size="small"
                        >搜索
                        </el-button
                        >
                    </el-form-item>
                </el-form>
                <el-table
                        :data="userList"
                        border
                        style="width: 100%"
                        ref="list"
                        stripe
                        size="small"
                >
                    <el-table-column
                            prop="userName"
                            label="用户姓名"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="phone"
                            label="用户手机号"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="hasAdministratorName"
                            label="角色"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="createTime"
                            label="创建时间"
                            align="center"
                    ></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-tooltip placement="top" content="删除">
                                <el-button
                                        type="text"
                                        style="color: red"
                                        @click="handleDeleteMerchantUser(scope.row)"
                                        size="small"
                                >
                                    移除
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-block">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageVo.current"
                            :page-sizes="[2, 10, 20, 50, 100]"
                            :page-size="pageVo.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                    >
                    </el-pagination>
                </div>
            </div>
            <div class="block-title">
                <i class="el-icon-s-opportunity"></i>关联业务线路
                <el-button type="text" @click="handleInitAddBusinessLine"
                >添加
                </el-button
                >
            </div>
            <div class="block">
                <el-table
                        :data="businessLineList"
                        border
                        style="width: 100%"
                        ref="list"
                        stripe
                        size="small"
                >
                    <el-table-column
                            prop="businessLineName"
                            label="业务线路名称"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="remark"
                            label="备注"
                            align="center"
                    ></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-tooltip placement="top" content="删除">
                                <el-button
                                        type="text"
                                        style="color: red"
                                        @click="handleDeleteBusinessLine(scope.row)"
                                        size="small"
                                >
                                    移除
                                </el-button>
                            </el-tooltip>
                            <el-tooltip placement="top" content="查看详情">
                                <el-button
                                        type="text"
                                        @click="handleViewBusinessLineDetails(scope.row)"
                                        size="small"
                                >
                                    查看
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="block-title">
                <i class="el-icon-s-order"></i>充值记录
                <el-button type="text" @click="handleInitAddTopUp">添加</el-button>
                <div class="block">
                    <el-table
                            :data="topUpList"
                            border
                            style="width: 100%"
                            ref="list"
                            stripe
                            size="small"
                    >
                        <el-table-column
                                prop="topUpTypeName"
                                label="充值类型"
                                align="center"
                        ></el-table-column>
                        <el-table-column
                                prop="topUpAmount"
                                label="充值金额"
                                align="center"
                        ></el-table-column>
                        <el-table-column
                                prop="remark"
                                label="备注"
                                align="center"
                        ></el-table-column>
                        <el-table-column
                                prop="createTime"
                                label="充值时间"
                                align="center"
                        ></el-table-column>
                    </el-table>
                    <div class="pagination-block">
                        <el-pagination
                                @size-change="handleTopUpSizeChange"
                                @current-change="handleTopUpCurrentChange"
                                :current-page="topUpPageVo.current"
                                :page-sizes="[2, 10, 20, 50, 100]"
                                :page-size="topUpPageVo.size"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="topUpTotalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="block-title">
                <i class="el-icon-s-custom"></i>线路配置信息
                <el-button type="text" @click="handleInitCreateLineResource"
                >添加
                </el-button
                >
            </div>
            <div class="block">
                <el-table
                        :data="lineResourcesList"
                        border
                        style="width: 100%"
                        ref="list"
                        stripe
                        size="small"
                >
                    <el-table-column
                            prop="merchantLineId"
                            label="ID"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="lineName"
                            label="线路名称"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="host"
                            label="访问地址"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="appId"
                            label="appId"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="appKey"
                            label="appKey"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="appSecret"
                            label="appSecret"
                            align="center"
                    ></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-tooltip placement="top" content="删除">
                                <el-button
                                        type="text"
                                        style="color: red"
                                        @click="handleDeleteLineResource(scope.row)"
                                        size="small"
                                >
                                    移除
                                </el-button>
                            </el-tooltip>
                            <el-tooltip placement="top" content="编辑">
                                <el-button
                                        type="text"
                                        @click="handleInitUpdateLineResource(scope.row)"
                                        size="small"
                                >
                                    编辑
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="block-title">
                <i class="el-icon-s-opportunity"></i>开放接口设置
                <el-button type="text" @click="handleInitSetOpenApiInfoOfMerchant">设置</el-button>
            </div>
            <div class="block">
                <el-table
                        :data="openApiInfoOfMerchantList"
                        border
                        style="width: 100%"
                        ref="list"
                        stripe
                        size="small"
                >
                    <el-table-column
                            prop="applyId"
                            label="应用ID"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="applyName"
                            label="应用"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="sendRecordUrl"
                            label="第三方话单推送地址"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="appKey"
                            label="appKey"
                            align="center"
                    ></el-table-column>
                    <el-table-column
                            prop="appSecret"
                            label="appSecret"
                            align="center"
                    ></el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog title="添加关联用户" :visible.sync="dialogForAddUser">
            <el-form
                    ref="addUserForm"
                    :model="addUserForm"
                    :rules="rules"
                    label-width="150px"
            >
                <el-row type="flex" justify="center">
                    <el-col :span="12">
                        <el-form-item label="用户手机号:" prop="phone">
                            <el-input
                                    v-model="addUserForm.phone"
                                    placeholder="用户手机号"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="用户是否为管理员:">
                            <el-select
                                    v-model="addUserForm.hasAdministrator"
                                    placeholder="用户是否为管理员"
                                    style="width: 100%"
                            >
                                <el-option label="是" :value="1"></el-option>
                                <el-option label="否" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="info" @click="dialogForAddUser = false"
                >取消
                </el-button
                >
                <el-button type="primary" @click="handleAddMerchantUser"
                >确认
                </el-button
                >
            </div>
        </el-dialog>
        <el-dialog
                title="添加关联业务线路"
                :visible.sync="dialogForAddBusinessLine"
        >
            <el-form
                    ref="addBusinessLineForm"
                    :model="addBusinessLineForm"
                    :rules="rules"
                    label-width="100px"
            >
                <el-row type="flex" justify="center">
                    <el-col :span="12">
                        <el-form-item label="业务线路:" prop="businessLineId">
                            <el-select
                                    v-model="addBusinessLineForm.businessLineId"
                                    placeholder="请选择业务线路"
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="item in businessLineListOptions"
                                        :key="item.businessLineId"
                                        :label="item.businessLineName"
                                        :value="item.businessLineId"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row type="flex" justify="center">
                    <el-col :span="12">
                        <el-form-item label="备注:" prop="remark">
                            <el-input
                                    v-model="addBusinessLineForm.remark"
                                    type="textarea"
                                    placeholder="请输入备注"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="info" @click="dialogForAddBusinessLine = false"
                >取消
                </el-button
                >
                <el-button type="primary" @click="handleAddBusinessLine"
                >确认
                </el-button
                >
            </div>
        </el-dialog>
        <el-dialog title="充值" :visible.sync="dialogForTopUp">
            <el-form
                    ref="topUpForm"
                    :model="topUpForm"
                    :rules="rules"
                    label-width="100px"
            >
                <el-row type="flex" justify="center">
                    <el-col :span="12">
                        <el-form-item label="充值类型:" prop="topUpType">
                            <el-select
                                    v-model="topUpForm.topUpType"
                                    placeholder="充值类型"
                                    style="width: 100%"
                                    filterable
                            >
                                <el-option
                                        v-for="item in topUpType"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="充值金额" prop="topUpAmount">
                            <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    v-model="topUpForm.topUpAmount"
                                    style="width: 100%"
                            />
                        </el-form-item>
                        <el-form-item label="备注">
                            <el-input
                                    type="textarea"
                                    v-model="topUpForm.remark"
                                    placeholder="备注"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="info" @click="dialogForTopUp = false">取消</el-button>
                <el-button type="primary" @click="handleAddTopUp">确认</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="title" :visible.sync="dialogForlineResource">
            <el-form
                    ref="lineResourceForm"
                    :model="lineResourceForm"
                    :rules="rules"
                    label-width="100px"
            >
                <el-row type="flex" justify="center">
                    <el-col :span="12">
                        <el-form-item label="线路:" prop="lineId">
                            <el-select
                                    v-model="lineResourceForm.lineId"
                                    placeholder="线路"
                                    style="width: 100%"
                                    filterable
                            >
                                <el-option
                                        v-for="item in lineList"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="线路请求地址">
                            <el-input
                                    v-model="lineResourceForm.host"
                                    placeholder="线路请求地址"
                            />
                        </el-form-item>
                        <el-form-item label="appKey" prop="appKey">
                            <el-input v-model="lineResourceForm.appKey" placeholder="appKey"/>
                        </el-form-item>
                        <el-form-item label="appId" >
                            <el-input v-model="lineResourceForm.appId" placeholder="appId"/>
                        </el-form-item>
                        <el-form-item label="appSecret" >
                            <el-input
                                    v-model="lineResourceForm.appSecret"
                                    placeholder="appSecret"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="info" @click="handleLoadLineResourceList"
                >取消
                </el-button
                >
                <el-button type="primary" @click="handleLineResourceSubmit"
                >确认
                </el-button
                >
            </div>
        </el-dialog>
        <el-dialog title="设置开放接口信息" :visible.sync="dialogForOpenApi">
            <el-form
                    ref="openApiInfoOfMerchant"
                    :model="openApiInfoOfMerchant"
                    :rules="rules"
                    label-width="150px"
            >
                <el-row type="flex" justify="center">
                    <el-col :span="18">
                        <el-form-item label="应用" prop="applyId">
                            <el-select v-model="openApiInfoOfMerchant.applyId" filterable placeholder="应用" style="width: 100%">
                                <el-option v-for="item in applyList" :key="item.applyId" :value="item.applyId"
                                           :label="item.applyName"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="第三方话单推送地址" prop="sendRecordUrl">
                            <el-input v-model="openApiInfoOfMerchant.sendRecordUrl" placeholder="第三方话单推送地址"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button size="small" @click="handleLoadOpenApiInfoOfMerchant">取消</el-button>
                <el-button type="primary" size="small" @click="handleSetOpenApiInfoOfMerchant">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        addMerchantBusinessLine,
        addTopUp,
        createLineResourcesInfo,
        deleteLineResourcesInfo,
        deleteMerchantBusinessLine,
        deleteMerchantUserRa,
        getApplyList,
        getLineResourcesList,
        getMerchantUserRaListByPage,
        getOpenApiInfoOfMerchant,
        initAddTopUp,
        initMerchantLineInfo,
        listBusinessLine,
        listMerchantBusinessLine,
        listTopUpByPage,
        saveMerchantUserRa,
        saveOpenApiInfoOfMerchant,
        updateLineResourcesInfo,
        getMerchantInfo,
        editCustomerRecoveryType
    } from '../../api'

    export default {
        data() {
            return {
                loading: false,
                dialogForAddBusinessLine: false,
                dialogForAddUser: false,
                dialogForTopUp: false,
                merchantId: this.$route.query.id,
                merchantName: this.$route.query.name,
                adminId: this.$store.getters.adminId,
                searchForm: {
                    adminId: this.$store.getters.adminId,
                    merchantId: this.$route.query.id,
                    userName: '',
                    phone: '',
                },
                pageVo: {
                    current: 1,
                    size: 10,
                },
                addUserForm: {
                    merchantId: this.$route.query.id,
                    phone: '',
                    hasAdministrator: 0,
                    adminId: this.$store.getters.adminId,
                },
                addBusinessLineForm: {
                    merchantId: this.$route.query.id,
                    businessLineId: '',
                    adminId: this.$store.getters.adminId,
                    remark: '',
                },
                rules: {
                    applyId: [{required: true, message: '请选择应用', trigger: 'change'}],
                    sendRecordUrl: [{required: true, message: '请输入第三方话单推送地址', trigger: 'blur'}],
                    phone: [{required: true, message: '请输入手机号码', trigger: 'blur'}],
                    topUpType: [
                        {required: true, message: '请选择充值类型', trigger: 'change'},
                    ],
                    topUpAmount: [
                        {required: true, message: '请输入充值金额', trigger: 'blur'},
                    ],
                    businessLineId: [
                        {required: true, message: '请选择业务线路', trigger: 'change'},
                    ],
                    lineId: [{required: true, message: '请选择线路', trigger: 'change'}],
                    appId: [{required: true, message: '请输入appId', trigger: 'blur'}],
                    appSecret: [
                        {required: true, message: '请输入appSecret', trigger: 'blur'},
                    ],
                },
                totalCount: 0,
                userList: [],
                userListOptions: [],
                businessLineList: [],
                businessLineListOptions: [],
                topUpType: [],
                topUpForm: {
                    merchantId: this.$route.query.id,
                    adminId: this.$store.getters.adminId,
                    topUpType: null,
                    topUpAmount: null,
                    remark: '',
                },
                topUpSearchForm: {
                    adminId: this.$store.getters.adminId,
                    merchantId: this.$route.query.id,
                },
                topUpList: [],
                topUpPageVo: {
                    current: 1,
                    size: 10,
                },
                topUpTotalCount: 0,
                lineResourcesList: [],
                dialogForlineResource: false,
                title: '',
                lineResourceForm: {
                    merchantLineId: null,
                    merchantId: this.$route.query.id,
                    adminId: this.$store.getters.adminId,
                    lineId: null,
                    host: null,
                    appId: null,
                    appKey: null,
                    appSecret: null,
                },
                lineList: [],
                applyList: [],
                dialogForOpenApi: false,
                openApiInfoOfMerchantList: [],
                openApiInfoOfMerchant: {
                    applyId: null,
                    sendRecordUrl: null,
                    appKey: null,
                    appSecret: null,
                    applyName: null
                },
                merchantInfoList: []
            }
        },
        created() {
            this.getMerchantInfo()
            this.loadUserList()
            this.loadBusinessLineList()
            this.handleLoadTopUpList()
            this.handleLoadLineResourceList()
            this.loadLineList()
            this.handleLoadOpenApiInfoOfMerchant()
        },
        methods: {
            handMerchantRecovery(row) {
              let customerRecoveryType = row.customerRecoveryType
              let message = customerRecoveryType == 1 ? "请确认关闭客户自动回收?" : "请确认开启客户自动回收?"
              this.$confirm(message, '修改客户公海自动回收状态', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(_ => {
                let params = {
                  merchantId: this.merchantId,
                  customerRecoveryType: customerRecoveryType == 1 ? 0 : 1
                }
                editCustomerRecoveryType(params).then(data => {
                  if (data) {
                    this.$notify({title: '成功', message: '修改成功', type: 'success'})
                    this.getMerchantInfo();
                  }
                })
              })
            },
            async getMerchantInfo() {
              this.merchantInfoList = []
              let param = {
                merchantId: this.merchantId
              }
              let data = await getMerchantInfo(param)
              this.merchantInfoList.push(data)
            },
            async loadApplyList() {
                this.applyList = await getApplyList({adminId: this.adminId})
            },
            async handleInitSetOpenApiInfoOfMerchant() {
                if (!this.applyList.length) {
                    await this.loadApplyList()
                }
                this.dialogForOpenApi = true
            },
            handleSetOpenApiInfoOfMerchant() {
                this.$refs.openApiInfoOfMerchant.validate(valid => {
                    if (valid) {
                        saveOpenApiInfoOfMerchant({
                            adminId: this.adminId,
                            merchantId: this.merchantId,
                            applyId: this.openApiInfoOfMerchant.applyId,
                            sendRecordUrl: this.openApiInfoOfMerchant.sendRecordUrl
                        }).then(data => {
                            if (data) {
                                this.$notify({
                                    title: '成功',
                                    message: '设置开放接口信息成功',
                                    type: 'success'
                                })
                                this.handleLoadOpenApiInfoOfMerchant()
                            }
                        })
                    }
                })
            },
            async handleLoadOpenApiInfoOfMerchant() {
                this.dialogForOpenApi = false
                const data = await getOpenApiInfoOfMerchant({merchantId: this.merchantId, adminId: this.adminId})
                if (data) {
                    Object.assign(this.openApiInfoOfMerchant, data)
                    if (data.applyId) {
                        this.openApiInfoOfMerchantList = [this.openApiInfoOfMerchant]
                    } else {
                        this.openApiInfoOfMerchantList = []
                    }
                }
            },
            handleLineResourceSubmit() {
                if ((this.title === '添加')) {
                    this.handleCreateLineResource()
                } else if ((this.title === '编辑')) {
                    this.handleUpdateLineResource()
                }
            },
            handleInitCreateLineResource() {
                this.title = '添加'
                this.dialogForlineResource = true
            },
            handleInitUpdateLineResource(row) {
                this.title = '编辑'
                Object.assign(this.lineResourceForm, row)
                this.dialogForlineResource = true
            },
            async loadLineList() {
                const data = await initMerchantLineInfo({adminId: this.adminId})
                this.lineList = data.lineList
            },
            async handleLoadLineResourceList() {
                const data = await getLineResourcesList({
                    adminId: this.adminId,
                    merchantId: this.merchantId,
                })
                this.dialogForlineResource = false
                this.title = ''
                this.lineResourcesList = data
                this.lineResourceForm.merchantLineId = null
                this.lineResourceForm.lineId = null
                this.lineResourceForm.appId = null
                this.lineResourceForm.appSecret = null
                this.lineResourceForm.host = null
            },
            async handleCreateLineResource() {
                this.$refs.lineResourceForm.validate((valid) => {
                    if (valid) {
                        createLineResourcesInfo({...this.lineResourceForm}).then((data) => {
                            if (data) {
                                this.$notify({
                                    title: '成功',
                                    message: '添加线路配置信息成功',
                                    type: 'success',
                                })
                                this.handleLoadLineResourceList()
                            }
                        })
                    }
                })
            },
            async handleUpdateLineResource() {
                this.$refs.lineResourceForm.validate((valid) => {
                    if (valid) {
                        updateLineResourcesInfo({...this.lineResourceForm}).then((data) => {
                            if (data) {
                                this.$notify({
                                    title: '成功',
                                    message: '编辑线路配置信息成功',
                                    type: 'success',
                                })
                                this.handleLoadLineResourceList()
                            }
                        })
                    }
                })
            },
            handleDeleteLineResource(row) {
                this.$confirm(`确定移除线路${row.lineName}吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    deleteLineResourcesInfo({
                        merchantLineId: row.merchantLineId,
                        adminId: this.adminId,
                    }).then((data) => {
                        if (data) {
                            this.$notify({
                                title: '成功',
                                message: '移除商家线路信息成功',
                                type: 'success',
                            })
                            this.handleLoadLineResourceList()
                        }
                    })
                })
            },

            async handleLoadTopUpList() {
                let param = {
                    ...this.topUpPageVo,
                    params: {
                        ...this.topUpSearchForm,
                    },
                }
                let data = await listTopUpByPage(param)
                this.topUpList = data.records
                this.topUpTotalCount = data.total
            },
            handleAddTopUp() {
                this.$refs.topUpForm.validate((valid) => {
                    if (valid) {
                        addTopUp({...this.topUpForm}).then((data) => {
                            if (data) {
                                this.$notify({
                                    type: 'success',
                                    message: '充值成功',
                                    title: '成功',
                                })
                                this.topUpForm.topUpAmount = null
                                this.topUpForm.topUpType = null
                                this.topUpForm.remark = null
                                this.dialogForTopUp = false
                                this.handleLoadTopUpList()
                            }
                        })
                    }
                })
            },
            onTopUpSubmit() {
                this.topUpPageVo.current = 1
                this.handleLoadTopUpList()
            },
            handleTopUpSizeChange(val) {
                this.topUpPageVo.size = val
                this.handleLoadTopUpList()
            },
            handleTopUpCurrentChange(val) {
                this.topUpPageVo.current = val
                this.handleLoadTopUpList()
            },
            async handleInitAddTopUp() {
                if (!this.topUpType.length) {
                    const data = await initAddTopUp({adminId: this.adminId})
                    this.topUpType = data.topUpType
                    this.dialogForTopUp = true
                } else {
                    this.dialogForTopUp = true
                }
            },
            goBack() {
                this.$router.back()
            },
            handleAddMerchantUser() {
                this.$refs.addUserForm.validate((valid) => {
                    if (valid) {
                        saveMerchantUserRa({...this.addUserForm}).then((data) => {
                            if (data) {
                                this.$notify({
                                    type: 'success',
                                    message: '添加关联用户成功',
                                    title: '成功',
                                })
                                this.dialogForAddUser = false
                                this.addUserForm.phone = ''
                                this.onSubmit()
                            }
                            this.loadUserList()
                        })
                    }
                })
            },
            handleAddBusinessLine() {
                this.$refs.addBusinessLineForm.validate((valid) => {
                    if (valid) {
                        addMerchantBusinessLine({...this.addBusinessLineForm}).then(
                            (data) => {
                                if (data) {
                                    this.$notify({
                                        type: 'success',
                                        message: '添加关联业务线路成功',
                                        title: '成功',
                                    })
                                    this.dialogForAddBusinessLine = false
                                    this.addBusinessLineForm.businessLineId = ''
                                    this.addBusinessLineForm.remark = ''
                                    this.onSubmit()
                                }
                                this.loadBusinessLineList()
                            }
                        )
                    }
                })
            },
            async handleInitAddBusinessLine() {
                if (this.businessLineListOptions.lengh > 0) {
                    this.dialogForAddBusinessLine = true
                } else {
                    let data = await listBusinessLine({adminId: this.adminId})
                    this.businessLineListOptions = data
                    this.dialogForAddBusinessLine = true
                }
            },
            handleInitAddUser() {
                if (this.userListOptions.lengh > 0) {
                    this.dialogForAddUser = true
                } else {
                    this.dialogForAddUser = true
                }
            },
            async loadUserList() {
                let param = {
                    ...this.pageVo,
                    params: {
                        ...this.searchForm,
                    },
                }
                let data = await getMerchantUserRaListByPage(param)
                this.userList = data.records
                this.totalCount = data.total
            },
            async loadBusinessLineList() {
                let data = await listMerchantBusinessLine({
                    adminId: this.adminId,
                    merchantId: this.merchantId,
                })
                this.businessLineList = data
            },
            onSubmit() {
                this.pageVo.current = 1
                this.loadUserList()
            },
            handleSizeChange(val) {
                this.pageVo.size = val
                this.loadUserList()
            },
            handleCurrentChange(val) {
                this.pageVo.current = val
                this.loadUserList()
            },
            handleDeleteMerchantUser(row) {
                if (row.merchantUserId) {
                    this.$confirm(`确定移除${row.userName}吗？`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        deleteMerchantUserRa({
                            merchantUserId: row.merchantUserId,
                            adminId: this.adminId,
                        }).then((data) => {
                            this.$notify({
                                type: 'success',
                                message: '移除成功',
                                title: '成功',
                            })
                            this.loadUserList()
                        })
                    })
                }
            },
            handleViewBusinessLineDetails(row) {
                if (row.businessLineId) {
                    this.$router.push({
                        name: 'business-line-details',
                        query: {
                            id: row.businessLineId,
                        },
                    })
                }
            },
            handleDeleteBusinessLine(row) {
                if (row.lineRaId) {
                    this.$confirm(`确定移除${row.businessLineName}吗？`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        deleteMerchantBusinessLine({
                            lineRaId: row.lineRaId,
                            adminId: this.adminId,
                        }).then((data) => {
                            this.$notify({
                                type: 'success',
                                message: '移除成功',
                                title: '成功',
                            })
                            this.loadBusinessLineList()
                        })
                    })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .content-mian {
        margin-top: 10px;

        .block-title {
            margin-top: 20px;
            font-weight: 500;
        }

        .block {
            margin-top: 10px;
        }
    }
</style>
